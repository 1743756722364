import * as React from 'react'

import Layout from '../layouts/layout'
// import sty from '../layouts/styles'
import { ContentBox } from 'src/components/Main/ContentBox'
import useImage from 'src/hooks/useImage'


const ServicePage = () => {

  const { siteRenov, siteNew, siteSaentis } = useImage()

  return (
    <Layout seo={{ title: 'Service' }}>
      <ContentBox
        src={false}
        title='Unser Service'
        subtitle='Mittels Materialien Ihrer Wahl flocken wir Hohlräume aus'
        text={<>
          Besonders für <strong>Brandsicherheit</strong>, <strong>Schall-</strong> und <strong>Wärmeisolation</strong> ist eine gute Dämmung entscheidend.
        </>}
        button={false}
      />
      <ContentBox
        src={siteRenov}
        title='Renovation'
        subtitle='Sparen Sie an Energiekosten'
        text={<>
          Die nachträgliche Dämmung von Altbauten stellt kein Problem dar.
          Durch eine kleine Anzahl an Bohrlöchern können Sie getrost auf eine Demontage der Boden-, Wand- und Deckenelemente verzichten.
        </>}
        button={false}
      />
      <ContentBox
        inverse
        src={siteNew}
        title='Neubau'
        subtitle='Wir sind von Grund auf dabei'
        text={<>
          Von kleinen Einfamilienhäusern bis zum grösseren Bauprojekt.
          Besonders wenn Isolationsmatten an Ihre Grenzen stossen, ist die Ausflockung mittels unserem Einblasverfahren oft die ideale Lösung.
        </>}
        button={false}
      />
      <ContentBox
        src={siteSaentis}
        title='Spezial'
        subtitle='Keine Umstände sind uns zu kompliziert'
        text={<>
          Es gibt immer wieder neue Herausforderungen.
          So durften wir etwa auf dem Berggasthaus Alter Säntis unsere Dienste erweisen.
          Per Helikopter fanden die Materialien und Maschine Ihren Weg nach oben.
        </>}
        button={false}
      />
    </Layout>
  )
}

export default ServicePage
